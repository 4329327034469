.Graph_title__2HO65 {
  padding-top: 3px;
  max-width: 140px;
  display: block;
  white-space: initial !important;
}

.SerieCard_card__1SnFA {
    border: 1px solid #ccc;
    margin-bottom: 30px;
    flex: 0 0 100%;
    box-sizing: border-box;
    padding-bottom: 15px;
    position: relative
}

.SerieCard_card__1SnFA a, .SerieCard_card__1SnFA small {
    color: #666; 
    font-size: 12px;
    font-weight: normal
}    

.SerieCard_card__1SnFA a {
    cursor: pointer;
    text-decoration: underline;
}

@media (min-width: 650px) {
    .SerieCard_card__1SnFA {
        flex-basis: 48%;
    }
}

@media (min-width: 1000px) {
    .SerieCard_card__1SnFA {
        flex-basis: 31%;
    }
}

.SerieCard_card__1SnFA header, .SerieCard_card__1SnFA footer {
    padding: 15px 40px 7px;
}

.SerieCard_card__1SnFA header h1 {
    margin-bottom: 0;
}

.SerieCard_card__1SnFA header small {
    margin-bottom: .7em;
    display: block;
}

.SerieCard_card__1SnFA footer {
    display: flex;
    justify-content: space-between
}

.SerieCard_card__1SnFA h1 {
    margin-top: 0;
    font-size: 24px;
    line-height: 1.5;
}


.Button_button__1DqCU {
  position: relative;
  vertical-align: top;
  padding: 5px 8px 8px;
  color:white;
  text-align: center;
  margin: 0 2px;
  color: #454545;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  background: #ecf0f1;
  border: 0;
  border: 1px solid #dadedf;
  border-bottom-width: 2px;
  cursor: pointer;
  box-shadow: inset 0 -2px #dadedf, 0 1px 10px #dadedf;
  border-radius: 3px;
}
.Button_button__1DqCU:first-of-type {
  margin-left: 0;
}
.Button_button__1DqCU:active, .Button_selected__2Bc0i {
  background:rgb(60, 141, 199);
  color: white;
  top: 1px;
  outline: none;
  box-shadow: none;
}
@media (min-width: 700px) {
    .Navigation_search__3wG_C {
        text-align: right;
    }
}

.Navigation_search__3wG_C input {
    display:inline-block;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    -webkit-appearance: none;
            appearance: none;
    box-shadow: none;
    border-radius: none;
    padding: 3px;
    border: solid 2px #c9c9c9;
    transition: border 0.3s;
}

.Navigation_distribute__2_TGN {
    margin-top: .75em;
}

@media (min-width: 790px) {
    .Navigation_distribute__2_TGN {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

.Navigation_nav__21nMp {
    border-top: 6px solid #000;
    padding-top: 10px;
}

.Navigation_nav__21nMp h4 {
    font-size: 80%;
    display: inline-block;    
    margin: 0;
    padding-bottom: 3px;
    padding-right: 7px;
    line-height: 1;
}

@media (max-width: 790px) {
    .Navigation_nav__21nMp h4 {
        min-width: 60px;
        text-align: left;
        display: block;
    }
}

.Navigation_line__2bfXa {
    text-align: left;
    line-height: 2;
    margin-bottom: 10px;
}

.Navigation_nav__21nMp a {
    text-decoration: underline;
    cursor: pointer;
}

.Navigation_nav__21nMp a.Navigation_selected__12cvT {
    text-decoration: none;
    cursor: default;
    font-weight: bold
}

.Navigation_checkboxLabel__2URgr {
    font-size: 12px;
    margin-left: 7px;
}
.Strip_strip__2H18z {
    text-align: center;    
    margin: 0 auto 1.25em;
    background:rgb(60, 141, 199);
    display: block;
    color: white;
    padding: 7px;
    text-decoration: none;
    margin-bottom: 2px
}

.Strip_strip__2H18z strong {
    text-decoration: underline;
}

.Header_header__6J3mP {
    text-align: center;    
    margin: 0 auto 1.25em;
}

.Header_header__6J3mP h1 {
    background: black;
    color: white;
    padding: 10px;
    margin: 0 0 2px;
}

header h1 a, header h1 a:visited, header h1 a:hover, header h1 a:active {
    color: white;
    text-decoration: none;
}

.Footer_footer__3V1cF {
  font-size: 90%;
  margin-bottom: 50px;
  line-height: 2;
}

.Footer_footer__3V1cF a {
  color: black;
  font-weight: bold;
}

.Footer_footer__3V1cF img {
  vertical-align: middle;
}

.Footer_footer__3V1cF time {
  font-style: italic;
}

.Footer_credits__1Sv4o {
  margin-top: 3.2rem;
  text-align: center;
}

.App_page__CKP-2 {
    padding: 20px 2.5%; 
    max-width: 1280px;
    margin: auto;
}

.App_container__1MQN3 > div {
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 0 auto 0;
    flex-wrap: wrap
}

.App_container__1MQN3 > div::after {
    content: '';
    flex: 0 0 31%;
}

@media (max-width: 650px) {
    .App_container__1MQN3 {
        justify-content: space-around
    }
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.recharts-bar-rectangle {
  cursor: pointer
}
