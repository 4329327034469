.header {
    text-align: center;    
    margin: 0 auto 1.25em;
}

.header h1 {
    background: black;
    color: white;
    padding: 10px;
    margin: 0 0 2px;
}

header h1 a, header h1 a:visited, header h1 a:hover, header h1 a:active {
    color: white;
    text-decoration: none;
}
