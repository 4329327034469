.card {
    border: 1px solid #ccc;
    margin-bottom: 30px;
    flex: 0 0 100%;
    box-sizing: border-box;
    padding-bottom: 15px;
    position: relative
}

.card a, .card small {
    color: #666; 
    font-size: 12px;
    font-weight: normal
}    

.card a {
    cursor: pointer;
    text-decoration: underline;
}

@media (min-width: 650px) {
    .card {
        flex-basis: 48%;
    }
}

@media (min-width: 1000px) {
    .card {
        flex-basis: 31%;
    }
}

.card header, .card footer {
    padding: 15px 40px 7px;
}

.card header h1 {
    margin-bottom: 0;
}

.card header small {
    margin-bottom: .7em;
    display: block;
}

.card footer {
    display: flex;
    justify-content: space-between
}

.card h1 {
    margin-top: 0;
    font-size: 24px;
    line-height: 1.5;
}

