.page {
    padding: 20px 2.5%; 
    max-width: 1280px;
    margin: auto;
}

.container > div {
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 0 auto 0;
    flex-wrap: wrap
}

.container > div::after {
    content: '';
    flex: 0 0 31%;
}

@media (max-width: 650px) {
    .container {
        justify-content: space-around
    }
}