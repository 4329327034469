@media (min-width: 700px) {
    .search {
        text-align: right;
    }
}

.search input {
    display:inline-block;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    padding: 3px;
    border: solid 2px #c9c9c9;
    -webkit-transition: border 0.3s;
    -moz-transition: border 0.3s;
    -o-transition: border 0.3s;
    transition: border 0.3s;
}

.distribute {
    margin-top: .75em;
}

@media (min-width: 790px) {
    .distribute {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

.nav {
    border-top: 6px solid #000;
    padding-top: 10px;
}

.nav h4 {
    font-size: 80%;
    display: inline-block;    
    margin: 0;
    padding-bottom: 3px;
    padding-right: 7px;
    line-height: 1;
}

@media (max-width: 790px) {
    .nav h4 {
        min-width: 60px;
        text-align: left;
        display: block;
    }
}

.line {
    text-align: left;
    line-height: 2;
    margin-bottom: 10px;
}

.nav a {
    text-decoration: underline;
    cursor: pointer;
}

.nav a.selected {
    text-decoration: none;
    cursor: default;
    font-weight: bold
}

.checkboxLabel {
    font-size: 12px;
    margin-left: 7px;
}